import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState, useRef } from "react";
import { BaseURL, validateEmail, validatePhoneNo } from "../utils/consts";
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import PasswordTextfield from "../components/passwordTextfield";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Loader } from "../components/Loader";
import EasyButton2 from "../components/v2/easyButton2";
import DoneIcon from "@mui/icons-material/Done";
import hero from "../utils/hero.png";
import Logo from "../components/logo";
import Captcha from "../components/captcha";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [forgotDialog, setForgotDialog] = useState(0);
  const [phoneNo, setPhoneNo] = useState("");
  const [otp, setOtp] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const otpCaptchaRef = useRef(null);

  const openCaptcha = () => {
    setError("");
    if (password.length < 6) {
      setError("Password must be atleast 6 characters long");
      return;
    }
    if (captchaRef.current) {
      captchaRef.current.execute();
    }
  };
 const openOtpCaptcha = () => {
   if (!validatePhoneNo(phoneNo)) {
     setError("Invalid phone number");
     return;
   }
   if (otpCaptchaRef.current) {
     otpCaptchaRef.current.execute();
   }
 };
  const login = async (captchaToken) => {
    try {
      setError("");
      setLoading(true);
      let res;
      if (validateEmail(email))
        res = await axios.post(`${BaseURL}/api/client/auth/login`, {
          email,
          password,
          captchaToken,
        });
      else if (validatePhoneNo(email))
        res = await axios.post(`${BaseURL}/api/client/auth/login`, {
          phoneNo: email,
          password,
          captchaToken,
        });
      else {
        setLoading(false);
        setError(
          "Either a valid email or a valid phone number must be provided"
        );
        return;
      }
      if (!res.data.phoneVerified) {
        setLoading(false);
        setPhoneNo(res.data.phoneNo);
        setForgotDialog(3);
        return;
      }
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("company", res.data.company);
      localStorage.setItem("username", res.data.username);
      localStorage.setItem(
        "pic",
        JSON.stringify({ profilePic: res.data.photoLink })
      );
      navigate("/v2/client/home", { replace: true });
    } catch (err) {
      setLoading(false);
      console.log(err);
      setError(err.response?.data?.message ?? err.message);
    }
  };

  const sendOtp = async (captchaToken) => {
    try {
      setLoading(true);
      setError("");
      setOtp("");
      await axios.post(`${BaseURL}/api/client/auth/sendForgotPasswordOtp`, {
        phoneNo,
        captchaToken,
      });
      setLoading(false);
      setForgotDialog(3);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e.response?.data?.message) setError(e.response?.data?.message);
      else setError(e.message);
    }
  };

  const otpVerify = async (otpValue) => {
    if (loading) return;
    try {
      setLoading(true);
      setError("");
      let res = await axios.post(
        `${BaseURL}/api/client/auth/verifyForgotPasswordOtp`,
        { otp: otpValue, phoneNo: phoneNo }
      );
      setLoading(false);

      localStorage.setItem("token", res.data.token);
      localStorage.setItem("company", res.data.company);
      localStorage.setItem("username", res.data.username);
      localStorage.setItem(
        "pic",
        JSON.stringify({ profilePic: res.data.photoLink })
      );
      setForgotDialog(4);
    } catch (e) {
      console.log(e);
      setError(
        e.response?.data?.message ? e.response?.data?.message : e.message
      );
      setLoading(false);
      setOtp("");
    }
  };

  const passwordReset = async () => {
    try {
      if (password.length < 6) {
        setError("Password should be atleast 6 characters long");
        return;
      }
      if (password !== confirmPassword) {
        setError("Confirm password is not matching");
        return;
      }
      setLoading(true);
      setError("");
      await axios.post(
        `${BaseURL}/api/client/auth/resetPassword`,
        { password },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setLoading(false);
      setForgotDialog(5);
    } catch (e) {
      setLoading(false);
      console.log(e);
      if (e.response?.data?.message) setError(e.response?.data?.message);
      else setError(e.message);
    }
  };

  return (
    <Stack
      minHeight={"100vh"}
      padding={2}
      justifyContent={"space-around"}
      alignItems={"center"}
      gap={5}
      direction={{ md: "row" }}
    >
      <Stack alignItems={"center"} alignSelf={"center"} spacing={4}>
        <Box
          component="img"
          alt="Happy Life"
          src={hero}
          sx={{
            width: { xs: "100%", md: "90%" },
            display: "block",
            transition: "0.3s",
            "&:hover": {
              transform: "translateY(-8px)",
            },
          }}
        />
        <Typography variant="h6" textAlign={"center"} maxWidth={"400px"}>
          "Step into a place where you're anonymously understood and supported."{" "}
        </Typography>
        <Stack alignItems={"self-start"} spacing={2}>
          {[
            "Expert Support: Connect with licensed professionals",
            "Confidential & Secure: Your privacy is paramount to us",
          ].map((e) => {
            return (
              <Stack
                direction={"row"}
                justifyContent={"start"}
                spacing={2}
                padding={0.5}
                key={e}
              >
                <DoneIcon /> <Typography> {e}</Typography>
              </Stack>
            );
          })}
        </Stack>
      </Stack>
      <Stack spacing={{ md: 5, xs: 4 }}>
        <Logo />
        <Typography variant="h6" sx={{ mt: 0, pt: 0 }}>
          Welcome to PeaceTwogether. Hope you have a wonderful time with us.
        </Typography>
        <TextField
          label="Enter email / phone no."
          value={email}
          onChange={(e) => setEmail(e.target.value.trim())}
          variant="outlined"
          margin="normal"
        />
        <PasswordTextfield
          password={password}
          setPassword={setPassword}
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          login={openCaptcha}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Checkbox></Checkbox>Remember Me
          </div>
          <Button color="secondary" onClick={() => setForgotDialog(1)}>
            Forgot Password?
          </Button>
        </div>
        <Captcha
          captchaRef={captchaRef}
          onCaptchaSuccess={(token) => {
            login(token);
          }}
        />
        <Captcha
          captchaRef={otpCaptchaRef}
          onCaptchaSuccess={(token) => {
            sendOtp(token);
          }}
        />
        {error !== "" && <Alert severity="error">{error}</Alert>}
        <Stack spacing={2}>
          <EasyButton2 label="Login" size="small" onClick={openCaptcha} />
          <EasyButton2
            label="Create a New Account"
            bgcolor="#0a9118"
            // "#F44336"
            onClick={() => navigate("/signup")}
          />
        </Stack>
      </Stack>
      <Dialog open={forgotDialog === 1} onClose={() => setForgotDialog(0)}>
        <DialogTitle>Forgot Password ?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reset password through otp verification of phone number
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={150}
          ></EasyButton2>{" "}
          <EasyButton2
            label={"Continue"}
            width={150}
            onClick={() => {
              setForgotDialog(2);
            }}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 2} onClose={() => setForgotDialog(0)}>
        <DialogTitle>Enter Phone number</DialogTitle>
        <DialogContent>
          {loading ? (
            <Loader height={"auto"} />
          ) : (
            <TextField
              label="Enter phone no"
              type={"tel"}
              fullWidth
              value={phoneNo}
              onChange={(e) => setPhoneNo(e.target.value.trim())}
              variant="outlined"
              margin="normal"
            />
          )}{" "}
          {error !== "" && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={150}
          ></EasyButton2>{" "}
          <EasyButton2
            label={"Continue"}
            width={150}
            onClick={openOtpCaptcha}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 3} fullWidth>
        <DialogTitle variant="h6" textAlign={"center"}>
          Please enter one time password to verify your account
        </DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={"center"}>
            Code has been sent to +91 {phoneNo}
          </DialogContentText>
          <Stack alignItems={"center"} m={{ lg: 5, xs: 0 }} my={5}>
            {loading ? (
              <Loader height={"auto"} />
            ) : (
              <MuiOtpInput
                sx={{
                  gap: { xs: 0.5, md: 2 },
                  minWidth: "250px",
                  marginInline: "auto",
                  display: "flex",
                  maxWidth: "650px",
                  justifyContent: "center",
                  paddingY: { md: 0, xs: 2 },
                }}
                length={6}
                value={otp}
                autoFocus
                onChange={(e) => {
                  setOtp(e);
                  setError("");
                }}
                onComplete={(e) => otpVerify(e)}
                TextFieldsProps={{
                  type: "number",
                  inputProps: {
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  },
                }}
              />
            )}{" "}
          </Stack>
          <Typography variant="">
            Didn't receive sms? <Button onClick={openOtpCaptcha}>Resend Otp</Button>
          </Typography>
          {error !== "" && <Alert severity="error">{error}</Alert>}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={150}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 4}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          {loading ? (
            <Loader height={"auto"} />
          ) : (
            <Stack>
              <PasswordTextfield
                password={password}
                setPassword={setPassword}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
              <PasswordTextfield
                label="Confirm Password"
                password={confirmPassword}
                setPassword={setConfirmPassword}
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
              {error !== "" && <Alert severity="error">{error}</Alert>}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Cancel"}
            onClick={() => setForgotDialog(0)}
            width={150}
          ></EasyButton2>
          <EasyButton2
            label={"Continue"}
            width={150}
            onClick={passwordReset}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
      <Dialog open={forgotDialog === 5}>
        <DialogTitle>Password Updated</DialogTitle>
        <DialogContent>Password update successful !</DialogContent>
        <DialogActions>
          <EasyButton2
            label={"Ok"}
            width={100}
            onClick={() => {
              setForgotDialog(0);
              navigate("/v2/client/home", { replace: true });
            }}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}
