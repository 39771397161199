import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { BaseURL, panelPageMargin } from "../../../utils/consts";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Panel from "../../../components/v2/panel";
import EasyButton2 from "../../../components/v2/easyButton2";
import AddIcon from "@mui/icons-material/Add";
export default function MoodJournal() {
  const [journals, setJournals] = useState([]);
  const [deleteJournal, setDelete] = useState({ dialog: false, journalID: "" });
  const [edit, setEdit] = useState({});
  const [display, setDisplay] = useState("list");
  const [index, setIndex] = useState(-1);
  const [error, setError] = useState("");

  const fetchData = async () => {
    try {
      let res = await axios.get(`${BaseURL}/api/client/journals/journals`, {
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      });
      setJournals(res.data);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const addJournal = async () => {
    try {
      let res = await axios.post(
        `${BaseURL}/api/client/journals/addJournal`,
        { title: "", content: "" },
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setJournals([res.data, ...journals]);
      setIndex(0);
      setEdit(res.data);
      setDisplay("journal");
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const editJournal = async () => {
    try {
      setError("");
      let res = await axios.put(
        `${BaseURL}/api/client/journals/editJournal`,
        { ...edit},
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      //replace the edited journal with the edited journal
      setJournals(journals.map((j) => (j._id === edit._id ? res.data : j)));
      setEdit(res.data);
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  const deleteJournalFn = async () => {
    try {
      setError("");
      await axios.delete(
        `${BaseURL}/api/client/journals/deleteJournal/${deleteJournal.journalID}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      setJournals(journals.filter((j) => j._id !== deleteJournal.journalID));
      setIndex(-1);
      setEdit({});
      setDelete({ dialog: false, journalID: "" });
      setDisplay("list");
    } catch (err) {
      console.log(err.message);
      setError(err.response?.data?.message ?? err.message);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Stack sx={{ ...panelPageMargin }}>
        <Panel />
        {error && (
          <Alert sx={{ marginTop: 5 }} severity="error">
            {error}
          </Alert>
        )}
        <Stack
          direction={"row"}
          alignItems={"start"}
          justifyContent={{ xs: "center", md: "space-between" }}
        >
          <Stack
            display={{ xs: display === "list" ? "flex" : "none", md: "flex" }}
            alignItems={"center"}
            paddingTop={3}
          >
            <Paper
              sx={{
                padding: 3,
                marginY: 1,
                marginX: { xs: 1, md: 5 },
                width: "300px",
                cursor: "pointer",
                borderRadius: 3,
              }}
              onClick={addJournal}
            >
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                {" "}
                <AddIcon sx={{ color: "#143F6B" }} />
                <Typography variant="h6" color={"#143F6B"}>
                  Add a New Journal
                </Typography>
              </Stack>
            </Paper>

            {journals.map((j, i) => {
              return (
                <Paper
                  onClick={() => {
                    setIndex(i);
                    setEdit(journals[i]);
                    setDisplay("journal");
                  }}
                  sx={{
                    padding: 3,
                    marginY: 1,
                    marginX: { xs: 1, md: 5 },
                    borderRadius: 3,
                    width: "300px",
                    cursor: "pointer",
                    backgroundColor: i === index ? "gainsboro" : "white",
                  }}
                  key={i}
                >
                  <Typography>{moment(j.timeStamp).format("dddd")}</Typography>
                  <Typography color={"textSecondary"} variant="body2">
                    {" "}
                    {moment(j.timeStamp).format("h:mm A, DD/MM/YY")}
                  </Typography>
                  <Typography variant="h6">{j.title}</Typography>
                  {j.content.substring(0, 60) +
                    (j.content.length > 60 ? "..." : "")}
                </Paper>
              );
            })}
            <Stack
              sx={{
                display: { xs: "flex", md: "none" },
                marginBottom: { xs: 2, md: 0 },
              }}
            >
              <EasyButton2
                width="250px"
                label="Add New Journal"
                onClick={() => addJournal()}
              ></EasyButton2>
            </Stack>
          </Stack>
          {index >= 0 ? (
            <Stack sx={{ width: "100%", margin: { xs: 1, md: 5 } }} spacing={2}>
              <Paper
                sx={{
                  display: {
                    xs: display === "journal" ? "flex" : "none",
                    md: "block",
                  },
                  padding: { xs: 2, md: 5 },
                  borderRadius: 3,
                  width: "100%",
                }}
              >
                <Stack width={"100%"}>
                  <IconButton
                    onClick={() => setDisplay("list")}
                    sx={{
                      alignSelf: "flex-start",
                      display: { xs: "flex", md: "none" },
                    }}
                  >
                    <ArrowBackRoundedIcon />
                  </IconButton>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Typography sx={{ flexGrow: 1 }}>
                      {moment(journals[index].timeStamp).format(
                        "h:mm A, DD/MM/YY"
                      )}
                    </Typography>
                    <Button
                      sx={{ textTransform: "none" }}
                      onClick={editJournal}
                      disabled={edit === journals[index]}
                    >
                      Save
                    </Button>
                    <IconButton
                      onClick={() =>
                        setDelete({
                          dialog: true,
                          journalID: journals[index]._id,
                        })
                      }
                      aria-label="delete"
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    placeholder="Enter title of your journal"
                    helperText={
                      journals[index].title ? "" : "Enter title of journal"
                    }
                    variant="standard"
                    value={edit.title}
                    fullWidth
                    multiline
                    margin="normal"
                    InputProps={{
                      sx: {
                        fontSize: { xs: "1.2rem", md: "1.8rem" }, // Adjust the font size as needed
                      },
                    }}
                    onChange={(e) => {
                      setEdit({ ...edit, title: e.target.value });
                    }}
                  />

                  <TextField
                    id="content"
                    name="content"
                    label="Content"
                    placeholder="Enter content of your journal"
                    helperText={
                      journals[index].content
                        ? ""
                        : "What do you feel grateful for? 3 things that happened today. How did you feel today?"
                    }
                    variant="standard"
                    minRows={12}
                    maxRows={Infinity} // Maximum number of rows
                    multiline
                    value={edit.content}
                    onChange={(e) => {
                      setEdit({ ...edit, content: e.target.value });
                    }}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      sx: {
                        fontSize: { xs: "1.0rem", md: "1.4rem" }, // Adjust the font size as needed
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-multiline": {
                        height: "100%", // Make textarea fill the available height
                        width: "100%",
                      },
                    }}
                  />
                </Stack>
              </Paper>
              <Stack
                alignSelf={"flex-end"}
                sx={{
                  display: { xs: "none", md: "flex" },
                  marginBottom: { xs: 2, md: 0 },
                }}
              >
                <EasyButton2
                  width="220px"
                  label="Save Journal"
                  onClick={editJournal}
                ></EasyButton2>
              </Stack>
            </Stack>
          ) : (
            <Stack
              display={{
                xs: "none",
                md: "flex",
              }}
              justifyContent={"center"}
              minHeight={"80vh"}
              width={"100%"}
              alignItems={"center"}
            >
              <Typography
                variant="h4"
                color={"gray"}
                paddingBottom={5}
                textAlign={"center"}
              >
                No Journal Selected
              </Typography>
              <EasyButton2
                label="Add New Journal"
                width="220px"
                onClick={() => addJournal()}
              ></EasyButton2>
            </Stack>
          )}
        </Stack>
      </Stack>
      <Dialog open={deleteJournal.dialog}>
        <DialogTitle>Delete Journal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the journal?
          </DialogContentText>
          {error && (
            <Alert sx={{ marginY: 2 }} severity="error">
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <EasyButton2
            width={100}
            label="Yes"
            onClick={deleteJournalFn}
          ></EasyButton2>
          <EasyButton2
            width={100}
            label="No"
            onClick={() => setDelete({ dialog: false, journalID: "" })}
          ></EasyButton2>
        </DialogActions>
      </Dialog>
    </>
  );
}
