import "./App.css";
import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import PolicyPage from "./pages/policyPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Career from "./pages/Career";
import SubscriptionPaymentSuccess from "./pages/v2/client/subscriptionPaymentSuccess";
import Home from "./pages/v2/client/home";
import CBTQuiz from "./pages/v2/client/cbtQuiz";
import Settings from "./pages/v2/client/settings";
import HelpCenter from "./pages/v2/client/helpCenter";
import Subscriptions from "./pages/v2/client/subscriptions";
import CorporateLanding from "./pages/corporates/landing";
import CorporateTnC from "./pages/corporates/t&c";
import CorporateSignUp from "./pages/corporates/signUp";
import LandingPage from "./pages/v2/landing/landing";
import CorporateLogin from "./pages/corporates/login";
import EasyBuddy from "./pages/v2/client/easyBuddy";
import MoodJournal from "./pages/v2/client/journal";
import NewServices from "./pages/v2/client/services";
import ConsultantsList from "./pages/v2/client/consultants";
import ConsultantDetails from "./pages/v2/client/consultantProfile";
import BookingQuestions from "./pages/v2/client/bookingQuestions";
import Booking from "./pages/v2/client/booking";
import SubscriptionPaymentFail from "./pages/v2/client/subscriptionPaymentFail";
import MeetingPaymentSuccess from "./pages/v2/client/meetingPaymentSuccess";
import MeetingPaymentFailed from "./pages/v2/client/meetingPaymentFailed";
import NewAddReview from "./pages/v2/client/addReview";
import Feedback from "./pages/v2/client/feedback";
import ClientVideoCall from "./pages/v2/client/clientVideoCall";
import Faqs from "./pages/v2/landing/faqs";
import AccessDenied from "./pages/accessDenied";
import UnsubscribeMail from "./pages/unsubscribeMail";
import AdminDashboard from "./pages/admin/adminDashboard";
import StressTracker from "./pages/v2/client/stressTracker";
import DepressionTracker from "./pages/v2/client/depressionTracker";
import LegalBuddy from "./pages/v2/client/legalBuddy";
import Profile from "./pages/consultant/Profile";
import MWS from "./pages/v2/client/mws";
import { Loader } from "./components/Loader";

const ConsultantLogin = lazy(() => import("./pages/consultant/Login"));
const ConsultantSignup = lazy(() => import("./pages/consultant/Signup"));
const ConsultantAppointment = lazy(() =>
  import("./pages/consultant/Appointment")
);
const ConsultantVideoCall = lazy(() => import("./pages/consultant/VideoCall"));
const ConsultantDashboard = lazy(() => import("./pages/consultant/Dashboard"));
const ConsultantFeedback = lazy(() =>
  import("./pages/consultant/ConsultantFeedback")
);
const ConsultantKnowledge = lazy(() =>
  import("./pages/consultant/knowledge/Knowledge")
);
const ConsultantPSS = lazy(() => import("./pages/consultant/knowledge/PSS"));
const ConsultantBDI = lazy(() => import("./pages/consultant/knowledge/BDI"));
const ConsultantCBT = lazy(() => import("./pages/consultant/knowledge/CBT"));
const ConsultantMeetingGuidelines = lazy(() =>
  import("./pages/consultant/knowledge/MeetingGuidelines")
);

const AdminLogin = lazy(() => import("./pages/admin/login"));
const AdminClientDetails = lazy(() => import("./pages/admin/clientDetails"));
const AdminSpecialistDetails = lazy(() =>
  import("./pages/admin/specialistDetails")
);
const AdminCompanyDetails = lazy(() => import("./pages/admin/companyDetails"));
// import SuperAdminDashboard from "./pages/admin/superAdminDashboard";
const SuperAdminDashboard = lazy(() =>
  import("./pages/admin/superAdminDashboard")
);
const CorporateDashboard = lazy(() => import("./pages/corporates/dashboard"));
const CorporateHelpCenter = lazy(() => import("./pages/corporates/helpCenter"));
const CorporateSettings = lazy(() => import("./pages/corporates/settings"));
const CorporateSubscriptions = lazy(() =>
  import("./pages/corporates/subscriptions")
);
const CorporateInterventions = lazy(() =>
  import("./pages/corporates/intervention")
);
const CorporateConsultations = lazy(() =>
  import("./pages/corporates/consultations")
);
const CorporateFeedbacks = lazy(() => import("./pages/corporates/feedbacks"));
const AdminPrivateRoute = ({ element }) => {
  if (!localStorage.getItem("adminToken")) {
    return <AccessDenied loginPath={"/viewPoint/login"} />;
  } else {
    return element;
  }
};

const OperatorPrivateRoute = ({ element }) => {
  if (
    !localStorage.getItem("operatorToken") &&
    !localStorage.getItem("adminToken")
  ) {
    return <AccessDenied loginPath={"/viewPoint/login"} />;
  } else return element;
};

const ConsultantPrivateRoute = ({ element }) => {
  if (!localStorage.getItem("consultantToken")) {
    return <AccessDenied loginPath={"/consultant/login"} />;
  } else {
    return element;
  }
};

const PrivateRoute = ({ element }) => {
  if (!localStorage.getItem("token")) {
    return <AccessDenied loginPath={"/login"} />;
  } else return element;
};

const CorporatePrivateRoute = ({ element }) => {
  if (!localStorage.getItem("corporateToken")) {
    return <AccessDenied loginPath={"/corporate/login"} />;
  } else return element;
};

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Helvetica",
      //'Georgia, sans serif',
      //textTransform: 'none',
      //fontSize: 16,
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        color: "white",
        input: {
          color: "black",
          backgroundColor: "white",
        },
        multiline: {
          backgroundColor: "white",
        },
      },
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <Routes>
            {/* ********** V2 LANDING ROUTES */}
            <Route index element={<LandingPage />}></Route>
            {/* ********** LANDING ROUTES */}
            <Route path="/faqs" element={<Faqs />}></Route>
            <Route path="/policies" element={<PolicyPage />}></Route>{" "}
            <Route path="/career" element={<Career />}></Route>
            <Route
              path="/unsubscribeMail/:token"
              element={<UnsubscribeMail />}
            ></Route>
            {/* ********** CLIENT ROUTES */}
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {/* **********  CONSULTANT ROUTES */}
            <Route path="/consultant/login" element={<ConsultantLogin />} />
            <Route path="/consultant/signup" element={<ConsultantSignup />} />
            <Route
              path="/consultant/dashboard"
              element={
                <ConsultantPrivateRoute element={<ConsultantDashboard />} />
              }
            ></Route>
            <Route
              path="/consultant/profile"
              element={<ConsultantPrivateRoute element={<Profile />} />}
            ></Route>
            <Route
              path="/consultant/knowledge"
              element={
                <ConsultantPrivateRoute element={<ConsultantKnowledge />} />
              }
            />
            <Route
              path="/consultant/knowledge/pss"
              element={<ConsultantPrivateRoute element={<ConsultantPSS />} />}
            />
            <Route
              path="/consultant/knowledge/bdi"
              element={<ConsultantPrivateRoute element={<ConsultantBDI />} />}
            />
            <Route
              path="/consultant/knowledge/cbt"
              element={<ConsultantPrivateRoute element={<ConsultantCBT />} />}
            />
            <Route
              path="/consultant/knowledge/meeting-guidelines"
              element={
                <ConsultantPrivateRoute
                  element={<ConsultantMeetingGuidelines />}
                />
              }
            ></Route>
            <Route
              path="/consultant/appointment/:meetingId"
              element={
                <ConsultantPrivateRoute element={<ConsultantAppointment />} />
              }
            />
            <Route
              path="/consultant/videoCall/:meetingId/:name"
              element={
                <ConsultantPrivateRoute element={<ConsultantVideoCall />} />
              }
            />
            <Route
              path="/consultant/submitFeedback/:meetingId"
              element={
                <ConsultantPrivateRoute element={<ConsultantFeedback />} />
              }
            ></Route>
            {/* ********** ADMIN ROUTES  */}
            <Route
              path="/viewPoint"
              element={<AdminPrivateRoute element={<SuperAdminDashboard />} />}
            />
            <Route path="/viewPoint/login" element={<AdminLogin />} />
            <Route
              path="/viewPoint/adminDashboard"
              element={<OperatorPrivateRoute element={<AdminDashboard />} />}
            />
            <Route
              path="/viewPoint/client/:clientID"
              element={<AdminPrivateRoute element={<AdminClientDetails />} />}
            ></Route>
            <Route
              path="/viewPoint/specialist/:specialistID"
              element={
                <OperatorPrivateRoute element={<AdminSpecialistDetails />} />
              }
            ></Route>
            <Route
              path="/viewPoint/company/:companyID"
              element={
                <OperatorPrivateRoute element={<AdminCompanyDetails />} />
              }
            ></Route>
            {/* ********** V2 CLIENT ROUTES */}
            <Route
              path="/v2/client/home"
              element={<PrivateRoute element={<Home />} />}
            ></Route>
            <Route
              path="/v2/client/mws"
              element={<PrivateRoute element={<MWS />} />}
            ></Route>
            <Route
              path="/v2/client/subscriptions"
              element={<PrivateRoute element={<Subscriptions />} />}
            ></Route>
            <Route
              path="/v2/client/cbtQuiz"
              element={<PrivateRoute element={<CBTQuiz />} />}
            ></Route>{" "}
            <Route
              path="/v2/client/stressTracker"
              element={<PrivateRoute element={<StressTracker />} />}
            ></Route>
            <Route
              path="/v2/client/depressionTracker"
              element={<PrivateRoute element={<DepressionTracker />} />}
            ></Route>
            <Route
              path="/v2/client/legalBuddy"
              element={<PrivateRoute element={<LegalBuddy />} />}
            ></Route>
            <Route
              path="/v2/client/feedback"
              element={<PrivateRoute element={<Feedback />} />}
            ></Route>
            <Route
              path="/v2/client/helpCenter"
              element={<PrivateRoute element={<HelpCenter />} />}
            ></Route>
            <Route
              path="/v2/client/settings"
              element={<PrivateRoute element={<Settings />} />}
            ></Route>
            <Route
              path="/v2/client/easyBuddy"
              element={<PrivateRoute element={<EasyBuddy />} />}
            ></Route>
            <Route
              path="/v2/client/moodJournal"
              element={<PrivateRoute element={<MoodJournal />} />}
            ></Route>
            <Route
              path="/v2/client/services"
              element={<PrivateRoute element={<NewServices />} />}
            ></Route>
            <Route
              path="/v2/client/consultants/:isLegal"
              element={<PrivateRoute element={<ConsultantsList />} />}
            ></Route>
            <Route
              path="/v2/client/consultantProfile/:consultantId"
              element={<PrivateRoute element={<ConsultantDetails />} />}
            ></Route>{" "}
            <Route
              path="/v2/client/questions/:meetingID/:consultantProfession"
              element={<PrivateRoute element={<BookingQuestions />} />}
            />{" "}
            <Route
              path="/v2/client/booking/:consultantID"
              element={<PrivateRoute element={<Booking />} />}
            />
            <Route
              path="/v2/client/meetingPaymentSuccess/:meetingID"
              element={<PrivateRoute element={<MeetingPaymentSuccess />} />}
            />
            <Route
              path="/v2/client/meetingPaymentFailed/:meetingID"
              element={<PrivateRoute element={<MeetingPaymentFailed />} />}
            />
            <Route
              path="/v2/client/subscriptionPaymentSuccess/:clientID/:subscriptionID"
              element={
                <PrivateRoute element={<SubscriptionPaymentSuccess />} />
              }
            />
            <Route
              path="/v2/client/subscriptionPaymentFail/:clientID/:subscriptionID"
              element={<PrivateRoute element={<SubscriptionPaymentFail />} />}
            />
            <Route
              path="/v2/client/videoCall/:meetingId/:name"
              element={<PrivateRoute element={<ClientVideoCall />} />}
            />
            <Route
              path="/v2/client/addReview/:meetingId"
              element={<PrivateRoute element={<NewAddReview />} />}
            ></Route>
            {/* ********** CORPORATE ROUTES */}
            <Route
              path="/corporate/landing"
              element={<CorporateLanding />}
            ></Route>
            <Route path="/corporate/t&c" element={<CorporateTnC />}></Route>
            <Route
              path="/corporate/signup"
              element={<CorporateSignUp />}
            ></Route>
            <Route path="/corporate/login" element={<CorporateLogin />}></Route>
            <Route
              path="/corporate/dashboard"
              element={
                <CorporatePrivateRoute element={<CorporateDashboard />} />
              }
            ></Route>
            <Route
              path="/corporate/helpCenter"
              element={
                <CorporatePrivateRoute element={<CorporateHelpCenter />} />
              }
            ></Route>
            <Route
              path="/corporate/subscriptions"
              element={
                <CorporatePrivateRoute element={<CorporateSubscriptions />} />
              }
            ></Route>
            <Route
              path="/corporate/interventions"
              element={
                <CorporatePrivateRoute element={<CorporateInterventions />} />
              }
            ></Route>
            <Route
              path="/corporate/consultations"
              element={
                <CorporatePrivateRoute element={<CorporateConsultations />} />
              }
            ></Route>{" "}
            <Route
              path="/corporate/feedbacks"
              element={
                <CorporatePrivateRoute element={<CorporateFeedbacks />} />
              }
            ></Route>
            <Route
              path="/corporate/settings"
              element={
                <CorporatePrivateRoute element={<CorporateSettings />} />
              }
            ></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
